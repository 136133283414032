import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { AuthContext } from '@shoreag/auth';
import NotFoundPage from '../../routes/default/404';
import Route from '../Route';
import SubtleText from '../SubtleText';
import isPermitted from '../../utilities/is-permitted';
import {
  PERMISSION_ACTIONS,
  PERMISSION_RESOURCES,
} from '../../utilities/constants';
import DatumCreateForm from '../CreateDatumForm';

const DatumCreatePage = ({ navigate }) => {
  const { user } = useContext(AuthContext);

  if (
    !isPermitted({
      desiredAction: PERMISSION_ACTIONS.W,
      desiredResource: PERMISSION_RESOURCES.DATUMS,
      ...user,
    })
  ) {
    return <NotFoundPage />;
  }

  return (
    <Route
      header={{
        icon: 'info',
        rightContainer: <SubtleText>* indicates required</SubtleText>,
        title: 'Create Datum',
        type: 'Datum',
      }}
      isPrivate
    >
      <DatumCreateForm onSuccess={({ id }) => navigate(`/datums/${id}`)} />
    </Route>
  );
};

DatumCreatePage.propTypes = {
  navigate: PropTypes.func.isRequired,
};

export default DatumCreatePage;
